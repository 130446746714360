$background: #FAFAFA;

$white: #fff;
$black: #000;
$blueGrey50: #EAF0F6;
$blueGrey300: #8EACBB;
$blueGrey500: #607D8B;
$blueGrey800: #34515E;
$grey50: #F5F5F5;
$grey200:#C7C7C7;
$grey700: #4F5B62;
$grey800: #263238;
$grey900: #000A12;

$pink400: #E50338;
$redA400-extralight: #FFE0E0;
$redA400-light: #FF616F;
$redA400: #FF1744;
$redA400-dark: #C4001D;
$greenA200-extralight: #E3F9E9;
$greenA200-light: #9FFFE0;
$greenA200: #69F0AE;
$greenA200-dark: #2BBD7E;
$orange700-light: #FFAD42;
$orange700: #F57C00;
$orange700-dark: #BB4D00;
$deepPurple600-light: #9162E4;
$deepPurple600: #5E35B1;
$deepPurple600-dark: #280680;
$deepPurple600-extralight: #EAE0FC;
$lightBlue-light: #65DAFF;
$lightBlue: #00A9EC;
$lightBlue-dark: #007AB9;
$lightBlue-extralight: #D0EBFD;

$brand-light: #8BD1FF;
$brand: #56A0D3;
$brand-dark: #1372A2;


$primary: $lightBlue;
$primaryDark: $lightBlue-dark;
$secondary: $greenA200;
$on-primary: $white;
$on-secondary: $black;
$on-background: $blueGrey800;
$on-background-dark: $white;
$box-shadow-color: rgba(0,0,0,0.16);

$header: $blueGrey800;
$navigation: $grey900;
$card-background: $white;
$card-background-dark: $blueGrey500;

$notification: $redA400;
$error: $redA400-dark;

$color-scheme: normal;