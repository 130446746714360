@import 'src/styles/colors.scss';
.card{
  margin-top: 1rem;
}
.pfp {
  border: 2px solid $lightBlue-light;
  border-radius: 50%;
  height: 10em;
  width: 10em;
  min-width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1139px) {
    margin : 0 auto;
    float: none;
    display: block;
  }
  .teamMemberPhoto {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    border: 2px solid $white;
  }
}