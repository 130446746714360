@import 'src/styles/colors.scss';

.legendTable {
  max-width: 100%;
  width: 450px;
  float: none;
  margin: 0 auto;
  max-height: 215px;
  border: 1px solid $grey200;
  overflow: auto;
  @media (max-height: 945px){
    max-height: 155px;
  }
  .legendHeader {
    font-weight: bold;
    border-bottom: 1px solid $grey200;
    background-color: $blueGrey50;
    div:nth-of-type(1) {
      width: 50%;
    }
    div:nth-of-type(2) {
      width: 50%;
      text-align: right;
    }
  }
  .legendRow {
    border-bottom: 1px solid $grey200;
    &:last-of-type {
      border-width: 0px;
    }
    .legendId {
      .legendColor {
        height: 16px;
        width: 16px;
        border-radius: 4px;
      }
    }
    div:nth-of-type(1) {
      width: 50%;
    }
    div:nth-of-type(2) {
      width: 50%;
      text-align: right;
    }
  }
}