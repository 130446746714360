@import 'src/styles/colors.scss';

.table {
  padding: 1em 0 0;
  margin-bottom: 0px;
  //border-top: 1px solid $blueGrey800;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  width: 100%;

  @media(max-width: 991px){
  overflow-x: scroll;
  }

  table {
    margin-bottom: 0px;
    border-top: 1px solid $blueGrey800;
  }
  tr {
    &:not(first-child){
      border-width: 0px!important;
    }

    th {
      border-top: 0px;
      background-color: $blueGrey50;
      vertical-align: middle;

      &:nth-child(1){
        border-right: 1px solid $blueGrey800;
      }

      &.categoryHeader {
        background-color: $blueGrey800;
        color: $white;
        text-align: center;
        h6 {
          color: $white;
          text-decoration: none;

          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
    td {
      border-top: 0px;
      border-color: $blueGrey300;
      &:nth-child(1) {
        background-color: $blueGrey50;
        color: $lightBlue;
        border-right: 1px solid $blueGrey800;
        a {
          color: $lightBlue;
          text-decoration: none;
          padding: 0;
        }
      }
    }
  }
  span {
    padding: 0 !important;
  }
}

tr.totalsRow {
  font-weight: bold;
  td {
    border-bottom-width: 0px;
    color: $grey900;
    background-color: $brand-light;
    &:nth-child(1) {
      color: $grey900;
      background-color: $brand-light;
    }
  }
}


.potentialText {
  color: #ff8a00;
}

.declinedText {
  color: #fd3c40;
}

.pendingText {
  color: #69c1ff;
}

.completeText {
  color: #00b842;
}

.tableKey {
  padding: 0 1em;
  display: flex;
  justify-content: space-evenly;

  p {
    max-width: 20%;
  }

  span {
    font-weight: bold;
  }
}

.tableHeader {
  //padding: 1em;
  display: block;
  justify-content: space-between;
  align-items: center;
}
/*
.tableFilter {
  display: flex;
  position: relative;
  flex-direction: column;
  float: right;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  background: #fff;
  margin-top: calc(-54px - 1rem);
  margin-right: 1rem;
  padding: 0.5rem 1rem 4px;
  border: 1px solid #C7C7C7;
  border-bottom-color: #fff;

  &:after {
    background-color: #fff;
    height: 12px;
    content: "";
    position: absolute;
    bottom: -13px;
    left: -10px;
    right: -10px;
  }

  label {
    font-weight: bold;
  }
}
*/

.tableFilter {
  display: flex;
  position: relative;
  float: right;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  @media (max-width: 991px){
    float: left;
    margin-left: 1rem;
  }
  label {
    font-weight: bold;
    margin-right: 0.5rem;
    width: 37%;
  }
}
.currency {
  text-align: right;
}

tr {

  .columnLabel {
    padding: 0 !important;
    &:nth-of-type(4){
      width: 230px;
    }
  }
}

.noRecs {
  text-align: center;
  padding-top: 25vh;
}