@import 'src/styles/colors.scss';

.cardNoPadding {
    background: $white;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 $box-shadow-color;
    min-height: 70vh;
}

.searchBarContainer {
    padding: 1em;
}