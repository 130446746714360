@import 'src/styles/colors.scss';

.searchBar {
    position: relative;
    height: 37px;

    input {
        padding: 0.5em 1rem;
        border: 1px solid $blueGrey300;
        border-radius: 4px;
        width: 20em;
        max-width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        background-color: transparent;

        &::placeholder {
            color: $blueGrey300;
        }
    }

    input:focus {
        outline: 2px solid $primary;
    }

    .icon {
        color: $blueGrey300;
        position: absolute;
        top: 0.5rem;
        left: 16rem;
       // z-index: -1;
    }

}
