@import 'src/styles/colors.scss';

.healthCheckHeader {
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media(min-width: 768px) and (max-width:1575px){
    width: 250px;
  }
  @media (min-width: 768px){
    align-items: center;
  }
}

.recommendationsHeader {
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (min-width: 768px){
    align-items: center;
  }
}

.keyTooltipButton {
  font-size: 1.25rem;
}

.keyTooltipInner {
  width: 640px;
  flex-direction: column;
  @media(max-width: 767px){
    width: 320px;
  }
  .tooltipHeader {
    color: $on-background-dark!important;
  }

}

.statsContainerDashboard {
  @media (max-width: 992px){
    position: relative!important;
    transform: none!important;
    top: auto!important;
    left: auto!important;
  }
}
.responsiveMt3__lg {
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
}
.responsiveMt3__md {
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}
.notesSection, .keepSection, .gridSection {
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
  }
}

.gridBtn {
  margin: 0 !important;
}

.clearGrid {
  text-align: right;
}

.tableControlsContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5rem;
  margin-top: 0px;

  @media (max-width: 1199px){
    display: block!important;
    width: 100%!important;
    position: relative!important;
    margin-top: 1rem;
    padding-right: 0px;
  }
  .tableControls {
    display: flex;
    justify-content: space-between;
    /*margin-bottom: 2em;*/
    @media (max-width: 991px){
      display: block;
    }
  }
}



.gridControls {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5em;
  margin-left: 1rem;
  @media (max-width: 768px){
    display: block;
  }
  @media (max-width: 992px){
    margin-top: 0.5rem;
    margin-left: 0px;
    justify-content: start;
  }

  .gridDropdown {
    display: flex;
    gap: 0.5em;
    @media (max-width: 768px){
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  select {
    width: 150px;
  }
}

.noRecs {
  text-align: center;
  padding-top: 25vh;
}