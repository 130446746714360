@import 'src/styles/colors.scss';

.responsiveMt3__lg {
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
}
.responsiveMt3__md {
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}

.checkSquareContainer {
    color: $greenA200-dark;
    font-size: 4em;
}

.materialsUploadedNumber {
    font-size: 2em;
}

.materialsUploadedText {
    font-weight: bold;
    font-size: 1.2em;
}

.statsContainerDashboard {
  @media (max-width: 992px){
    position: relative!important;
    transform: none!important;
    top: auto!important;
    left: auto!important;
  }
}

.meetingContainerKOM {
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
  .meetingCalendar {
    font-size: 4em;
    padding-right: 1rem;
    color: $grey900;
  }
}

.needSpycare {
  cursor: not-allowed;
}

.needSpycare a {
  pointer-events: none;
}

.komNotes {
  ol {
    li {
      cursor: auto;
      box-shadow: none;
      font-weight: normal;
      text-align: left;
      display: list-item;
      padding: 0;
      transition: none;
      list-style-type: decimal;
    }

    li:hover {
      background-color: inherit;
    }
  }
}