.instructionsContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

.instructionsContainer > * {
    flex: 1;
  }

.uploadContainer {
    margin-top: 1rem;
}

.uploadedFileContainer {
  display: flex;
  flex-direction: column;
}

.uploadedFileContainer div:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.uploadedFileContainer div:last-child {
  border-bottom: #555 1.3px solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.checkListSection {
  h4{
    margin-bottom: 0;
  }
  li {
    list-style: inherit;
    display: list-item!important;
    cursor: auto;
    box-shadow: none;
    min-width: 0;
    min-height: 0;
    line-height: inherit;
    text-align: inherit;
    font-weight: inherit;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    transition: none;
    border-radius: 0px;
    padding: 0px;
    margin-bottom: 0px;
    &:hover {
      background-color: transparent;
    }
  }
}

.spacer {
  p {
    margin-bottom: 1rem;
  }
}