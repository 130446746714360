@import 'src/styles/colors.scss';

thead {
    background: $blueGrey50;
}

table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
}

td {
    border-bottom: 1px solid $blueGrey300;
}

th, td {
    padding: 0.5em 1em;
    text-align: left;
}

.dataRow:hover {
    td {
        button {
            visibility: visible;
        }
    }
}

.dataRow {
    td {
        button {
            visibility: hidden;
        }
    }
}