@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@300;400;500;700&display=swap');
@import 'src/styles/colors.scss';

/* The following block can be included in a custom.scss */

/* make the customizations */

$enable-cssgrid: false;

// scss-docs-start theme-color-variables
$primary:       $lightBlue;
$secondary:     $greenA200;
$notification:  $redA400;
$cancel:  $redA400-dark;
$error:  $redA400-dark;
$success: $greenA200-dark;
$confirmation: $greenA200-dark;
$warning: $orange700-light;

// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "notification": $notification,
  "cancel": $cancel,
  "error": $error,
  "warning":  $orange700-light,
  "warning-alt": $orange700,
  "success": $success,
  "confirmation":  $confirmation,
  "confirmationLight": $greenA200,
  "blueGreyDark": $blueGrey800,
  "blueGrey": $blueGrey500,
  "blueGreyLight": $blueGrey300,
  "blueGreyExtraLight": $blueGrey50
);
// scss-docs-end theme-colors-map

// Body

$body-bg: $background;
$body-color: $blueGrey800;
$headings-color: $grey800;

// Typography

$font-size-base: 0.875rem;
$font-size-sm: $font-size-base * .875 ;
$font-size-lg: $font-size-base * 1.25;
$font-family-base: 'Inter', sans-serif;

$headings-font-family: 'DM Sans', sans-serif;
$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2.35;
$h2-font-size: $font-size-base * 1.65;
$h3-font-size: $font-size-base * 1.4375;
$h4-font-size: $font-size-base * 1.1875;
$h5-font-size: $font-size-base * 1.4375;
$h6-font-size: $font-size-base * 1.143;

// Container

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1620px
);

// Spacing

$spacer: 1rem;

// Grid
$grid-gutter-width: 1rem;
.col {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.row+.row {
  margin-top: $spacer;
}

// Dropdown

$dropdown-link-active-bg: $grey700;

// Navbar

$navbar-dark-color: $grey200;
$navbar-dark-hover-color: $white;
$navbar-nav-link-padding-x: $spacer;


.dropdown-toggle {
  &:after {
    border-style: solid;
    border-width: 0.235em 0.235em 0 0!important;
    border-color: $grey200!important;
    content: '';
    display: inline-block;
    height: 0.55em;
    width: 0.55em;
    position: relative;
    vertical-align: 0.35em;
    vertical-align: top;
    transform: rotate(135deg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }

  &:hover, &:active, &:focus  {
    &:after {
      border-color: $white!important;
    }
  }
}

.navbar-dark .navbar-nav .show {
  .dropdown-toggle {
    &:after {
      border-color: $white!important;
    }
  }
}

.dropdown-menu {
  background: $grey900!important;
  border-radius: 0px!important;

  .dropdown-item {
    color: $grey200!important;

    &:hover, &:focus{
      color: $white!important;
      background: $grey800!important;
    }
  }
  .dropdown-divider {
    border-top-color: $white;
  }
}

.dropdown-item.disabled {
  pointer-events: fill!important;
  cursor: not-allowed;
}


// Card

$enable-shadows: true;
$card-border-color: $grey200;
$card-box-shadow: 0px 3px 6px $box-shadow-color;
$card-cap-bg: transparent;
$card-cap-padding-y: 1rem;
$card-title-spacer-y: $spacer;
$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;

.card {
  padding-left: 0px!important;
  padding-right: 0px!important;
  border-width: 0px!important;
}
.card.bg-dark {
  background: $blueGrey500;
  color: $white;
  .text-muted {
    color: $grey200!important;
  }
  h1,h2,h3,h4,h5,p,li {
    color: $white;
    .text-muted {
      color: $grey200!important;
    }
  }
}
.card-footer {
  text-align: center;

  .card-link {
    color: $primary;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      color: $primaryDark;
    }
  }
}
.card+.card {
  margin-top: $spacer;
}

// Buttons

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-lg:  1.25rem;
$input-btn-padding-x-lg:  2.5rem;
$btn-font-weight: bold;
.btn-primary, .btn-outline-primary:hover {
  color: $white!important;
}
.btn-secondary, .btn-outline-secondary:hover {
  color: $grey800!important;
}
.btn+.btn {
  margin-left: 8px;

}

// List Group

$list-group-color: $blueGrey800;
$list-group-bg: $background;
$list-group-hover-bg: $blueGrey50;
$list-group-active-bg: $primary;


// Modal

$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";