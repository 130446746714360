@import 'src/styles/colors.scss';

.barContainer {
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  .chartOuter {
    width: 100%;
  }
  .chartSum {
    //width: 10%;
  }
}

.openContainer {
  text-align: center;
}