@import 'src/styles/colors.scss';

.contractSummary {
    justify-content: center;
}

.contractSummary > * {
    flex: 1;
}

.summaryValue {
    font-size: 2em;
}

.expired {
    color: $redA400-dark;
}

.expire30Days {
    color: $orange700-light;
}

.expire60Days {
    color: $grey200;
}

.topContracts {
    color: $greenA200-dark;
}