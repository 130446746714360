@import 'src/styles/colors.scss';

.cardContainerVh100 {
  // 100vh - HeaderBar - DashboardNav - Page Title - Card Margin - Card Gutter
  // min-height: calc(100vh - 72px - 53px - 39px - 2rem - 1rem);
  min-height: 40vh;
  .topRow {
    min-height: 200px;
  }
}

.projectLead {
  display: flex;
  justify-content: end;
  margin-bottom: 1.5em;
  width: 50%;
  max-width: 100%;
  grid-column-gap: 1rem;
  @media (max-width: 1139px) {
    flex-direction: column;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  .projectLead p {
    margin: 0;
  }
  .leadInfo {
    align-self: center;
    width: 50%;
    @media (max-width: 1139px) {
      width: 100%;
      margin-top: 1rem;
      text-align: center;
    }
  }

  .pfp {
    border: 2px solid $lightBlue-light;
    border-radius: 50%;
    height: 9em;
    width: 9em;
    min-width: 9em;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1139px) {
      margin : 0 auto;
      float: none;
      display: block;
    }


    .teamMemberPhoto {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      border: 2px solid $white;
  }
  }
}

.meetingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
  gap: 0.5em;
  width: 50%;

  @media (max-width: 991px){
    width: 100%;
  }
}

.meetingCalendar {
  font-size: 5em;
}

.opportunityCounts, .opportunityValue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opportunities, .implemented {
  font-size: 2em;
  font-weight: bold;
}

/*.opportunities {
  color: #F6891B;
}

.implemented {
  color: #C71121;
}

.opportunitiesTotal, .opportunityAmount, .savingsTotal {
  font-weight: bold;
  color: #9EB8C5;
  font-size: 1.4em;
}*/

.opportunityAmount {
  font-size: 1em;
}

.savingsTotal {
  margin-bottom: 0.75em;
}

.progressBar {
  height: 8em;
  width: 8em;
  margin: 0 auto 1rem;
  float: none;
  display: block;
}

.recommendationsAlertIcon {
  position: absolute;
  right: 1.5rem;
}

.resourcesContainer {
  @media (min-width: 768px){
    overflow-y: scroll;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 767px){
    padding-bottom: 1rem;
  }
  @media (min-width: 992px){
    height: 174px;
  }
  @media (min-width: 1680px){
    height: 174px;
  }
  hr {
    margin: 0.35rem 0;
  }
  .resourceRow:last-of-type {
    hr {
      display: none;
    }
  }
  .resource {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .LINK, .PDF {
      border-radius: 3em;
      padding: 0.2em 1em;
    }

    .LINK {
      background-color: $lightBlue-extralight;
      color: $lightBlue-dark;
    }

    .PDF {
      background-color: $deepPurple600-extralight;
      color: $deepPurple600;
    }
  }
}

.nextHealthCheck h6 {
  text-align: center;
}

.contractsContainer {
  height: 100%;
}

.resourcesContainer {
  overflow: auto;
}

.serviceTile img {
  // transition: all 1s 0s ease;
  transition: transform 1s ease;
  //transform-origin: left top;
}

.serviceTile:hover img {
  transform: scale(1.1);
}