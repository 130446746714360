.uploadedFileView {
    padding: 2em;
    background-color: #fff;
    border-top: 1.3px solid #555;
    border-left: 1.3px solid #555;
    border-right: 1.3px solid #555;
    text-align: left;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
}

.fileName {
    flex: 1.2;
}

.fileSize {
    flex: 3;
}

.uploadedFileButton {
    background-color: #fff;
    border: #555 1px solid;
    padding: .5em 1em;
    border-radius: 5px;
    font-weight: 600;
    color: #555;
    cursor: pointer;
}