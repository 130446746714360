@import 'src/styles/colors.scss';

.fileUploaderContainer {
    padding: 1em;
    border: 2px dashed $brand-dark;
    border-radius: 4px;
    min-height: 20vh;
    background-color: rgba($brand-light, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.uploadView {
    gap: 1em;
}

.fileUploaderContainer input {
    display: none;
}

.fileUploaderContainer label {
    padding: .5em 3em;
    border: 2px solid $primary;
    border-radius: 4px;
    background-color: $white;
    color: $primary;
    font-weight: bold;
    cursor: pointer;
}
.fileUploaderContainer p {
    display: block;
}
.fileViewContainer {
    width: 100%;
    display: flex;
    gap: 1em;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.uploadButton {
    background-color: $primary;
    border: none;
    padding: .5em 4em;
    color: $white;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }