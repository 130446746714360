.updateButtons {
  button {
    padding: 0.5em;
    font-weight: normal;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
}