@import 'src/styles/colors.scss';

.resourceRow:last-of-type {
    hr {
      display: none;
    }
  }
  .resource {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .LINK, .PDF {
      border-radius: 2em;
      padding: 0.2em .5em;
    }

    .LINK {
      background-color: $lightBlue-extralight;
      color: $lightBlue-dark;
    }

    .PDF {
      background-color: $deepPurple600-extralight;
      color: $deepPurple600;
    }
  }