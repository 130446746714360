.cellDetailPanel {
    position: relative;
    padding: 5px;
}

.cellDetail {
    padding-top: 4px;
}
  
.cellDetails {
    padding: 5px 5px 5px 25px;
    margin: 5px;
    width: fit-content;
}