@import 'src/styles/colors.scss';

.trackerStart {
  padding: 1rem;
  @media (max-width: 991px){
    text-align: center;
  }
}
.trackerGraphicContainer {
  text-align: center;
  img {
    width: 1024px;
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
    @media (max-width: 767px){
      display: none;
    }
  }
}