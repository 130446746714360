@import 'src/styles/colors.scss';

.nav {
    background-color: $grey800;
    min-height: 5em;
    display: flex;
    align-items: center;
}

.navItems {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin: 1rem 0rem;
    align-items: center;
    gap: 2em;

    .statusContainer {
        display: flex;
        align-items: center;
        gap: 1em;
        font-weight: 500;
    }
}

.logoContainer {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .dropdownLogo {
        .logo {
            height: 32px;
            width: 115px;
            color: $grey200;
            flex-grow: 1;
            // For logo in dropdown
            margin-right: 0.5rem;

            a {
                text-decoration: none;

                img {
                    color: $grey200;
                    font-size: 28px;
                    line-height: 28px;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .dropdownDescription {
        text-align: center;
        background-color: $grey700;
        color: $grey200;
        padding: 0.25rem;
        border-bottom: 1px solid $blueGrey300;
        font-weight: 500;

    }
    .dropdownLogoItem {
        width: 237px;
        padding: 0.75rem 0rem;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        .indicator {
            color: $greenA200-dark;
            font-weight: bold;
        }
        img {
            width: 92px;
        }
    }
}

.statusContainer {
    color: $grey200;

    .projectStatus {
        display: flex;
        align-content: center;
        justify-content: center;

        .label {
            align-self: center;
            margin-right: 0.5rem;
        }
        .statusHeader {
            display: block;
            align-self: center;
            height: 40px;

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }

    .savingsStatus {
        display: block;
    }

    .notificationsContainer {
        display: flex;
        align-content: center;
        justify-content: center;
        position: relative;

        .notificationsIcon {
            font-size: 1.5rem;
            align-self: center;
        }
        .notificationsCounter {
            background: $notification;
            color: $white;
            text-align:center;
            vertical-align: center;
            height: 1.125rem;
            width: 1.125rem;
            font-size: 0.695rem;
            border-radius: 99em;
            margin-left: -0.495rem;
            margin-top: 0.125rem;
        }
    }

    .companyName {
        display: flex;
        align-content: center;
        justify-content: center;
        border-left: 2px solid $blueGrey500;
        padding-left: 1rem;

        .dropdownUser {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin-right: 0.5rem;
            color: $grey200;

            .name {
                align-self: center;
                font-weight: 500;
            }
            .user {
                display: flex;
                align-content: center;
                justify-content: center;
                cursor: pointer;
                .userIcon {
                    display: block;
                    font-size: 1.5rem;
                    align-self: center;
                    padding-left: 0.5rem;
                }
                .userActions {
                    display: block;
                    align-self: center;
                    padding-left: 0.5rem;
                }
            }
        }
    }
}