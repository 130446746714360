@import 'src/styles/colors.scss';

.cardContainer {
  height: 100%;
  // - navbar - DashboardNav - Title - Project Tracker - Margin + Overhang
  //min-height: calc(100vh - 72px - 53px - 39px - 199px - 4.5rem + 110px);
  min-height: 602px;

  .statsContainerDashboard {
    @media (max-width: 992px){
      position: relative!important;
      transform: none!important;
      top: auto!important;
      left: auto!important;
    }
  }
  .responsiveMt3__lg {
    @media (max-width: 1200px) {
      margin-top: 1rem;
    }
  }
  .responsiveMt3__md {
    @media (max-width: 992px) {
      margin-top: 1rem;
    }
  }
}

.projectLead {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5em;
  max-width: 100%;
  grid-column-gap: 1rem;
  @media (max-width: 1139px) {
    flex-direction: column;
  }

  .projectLead p {
    margin: 0;
  }
  .leadInfo {
    align-self: center;
    width: 50%;
    @media (max-width: 1139px) {
      width: 100%;
      margin-top: 1rem;
      text-align: center;
    }
  }

  .pfp {
    border: 2px solid $lightBlue-light;
    border-radius: 50%;
    height: 7em;
    width: 7em;
    min-width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1139px) {
      margin : 0 auto;
      float: none;
      display: block;
    }


    .teamMemberPhoto {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      border: 2px solid $white;
  }
  }
}



.teamMembers {
  margin-bottom: 1rem;
  .teamMember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
    .ellipsisWrapper {
      width: calc(100% - 2rem);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

  }
  .teamMemberRow {
    &:last-of-type{
      hr {
        display: none;
      }
    }
  }
  hr {
    margin: 0.35em 0;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.myTeamBtn {
  background-color: #00A9EC;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0.5em 1em;
  margin-top: 1em;
  cursor: pointer;
}

.meetingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.meetingCalendar {
  font-size: 5em;
}

.opportunityCounts, .opportunityValue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opportunities, .implemented {
  font-size: 2em;
  font-weight: bold;
}

/*.opportunities {
  color: #F6891B;
}

.implemented {
  color: #C71121;
}

.opportunitiesTotal, .opportunityAmount, .savingsTotal {
  font-weight: bold;
  color: #9EB8C5;
  font-size: 1.4em;
}*/

.opportunityAmount {
  font-size: 1em;
}

.savingsTotal {
  margin-bottom: 0.75em;
}

.progressBar {
  height: 8em;
  width: 8em;
  margin: 0 auto 1rem;
  float: none;
  display: block;
}

.savingsCapturedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.percentileGaugeContainer {
  max-height: 15em;
  max-width: 15em;
  padding-right: 2.5em;
  div {
    div {
      display: none !important;
    }
  }
}

.gaugeLegend {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.gaugeLegendItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.poorPercentile {
  background-color: $redA400;
  height: 1em;
  width: 1em;
}

.averagePercentile {
  background-color: $orange700-light;
  height: 1em;
  width: 1em;
}

.goodPercentile {
  background-color: $lightBlue;
  height: 1em;
  width: 1em;
}

.excellentPercentile {
  background-color: $greenA200-dark;
  height: 1em;
  width: 1em;
}

.resourcesContainer {
  hr {
    margin: 0.35rem 0;
  }
  .resourceRow:last-of-type {
    hr {
      display: none;
    }
  }
  .resource {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .LINK, .PDF {
      border-radius: 3em;
      padding: 0.2em 1em;
    }

    .LINK {
      background-color: $lightBlue-extralight;
      color: $lightBlue-dark;
    }

    .PDF {
      background-color: $deepPurple600-extralight;
      color: $deepPurple600;
    }
  }
}
