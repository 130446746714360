@import 'src/styles/colors.scss';

.tabList {
    padding-left: 1em;
    margin: 0;
    display: flex;
    gap: .3em;
    @media (max-width: 767px) {
        padding-left: 0px;
        display: block;
    }
}
