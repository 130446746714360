@import 'src/styles/colors.scss';

.fileView {
    padding: 2em;
    background-color: $white;
    border: 1px solid $blueGrey500;
    border-radius: 5px;
    text-align: center;
    width: calc(16.666% - 1rem);
    height: 110px;
    overflow: hidden;
    overflow-wrap: break-word;
}

.fileSize {
    font-size: 20px;
}

.fileName {
    font-size: 12px;
    height: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}