@import 'src/styles/colors.scss';

.tabListItem {
    background-color: $grey200;
    z-index: 0;
}

.tabListActive {
    background-color: $white;
    color: $primary;

    &:after {
        content: '';
        display: block;
        background-color: #00A9EC;
        height: 4px;
        border-radius: 99px;
        min-width: 8em;
        width: 60%;
        bottom: 0px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        @media (max-width: 767px) {
            bottom: 5px;
        }
    }
    &:hover {
        background: $white;
    }
}

li {
    position: relative;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.875rem 2rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    box-shadow: 2px -3px 8px -4px $box-shadow-color;
    min-width: 12em;
    min-height: 2em;
    line-height: 1.275;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

    &:hover {
        //color: $primary;
        background-color: lighten($grey200, 10%);
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}