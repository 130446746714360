@import 'src/styles/colors.scss';

body {
  overflow-x: hidden;
}

.loginWrapper {
  position: relative;
  height: 100vh;
  min-height: 250px;
  background: $background;
  margin-top: -72px;
  z-index: 2;

  @media (max-width: 991px){
    margin-top: -95px;
  }

  .loginCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loginLogo {
    text-align: center;
    margin: 0 auto;
    float: none;
    width: 300px;

    @media (max-width: 991px){
      width: 300px;
      max-width: 100%;
    }
    @media (max-width: 991px){
      width: 300px;
      max-width: 100%;
    }
  }
}